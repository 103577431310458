<template>
  <section class="m-goods-comments m-scroll">
    
    <!-- 头部导航 -->
    <m-header title="商品评价">
      <router-link to="/zh/cart" slot="right" class="solt-right"><img src="@/assets/comment/gouwu@2x.png"  alt="cart"></router-link>
    </m-header>
    <van-list v-model="loading" :finished="finished"  @load="onLoad">
      <comment-list :data="list"></comment-list>
      <p class="search-no" v-if="list.length == 0 && !loading">暂无更多评论</p>
    </van-list>
   
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import CommentList  from '@/components/zh/goods-comment.vue'

import { getComment } from '@/api/zh/detail.js'
import { parseTime } from '@/untils/js/common.js'
export default {
  name:'Index',
  components:{ MHeader, CommentList },
  data(){
    return {
      loading:false,
      finished:false,
      list: [],
      page: 1
    }
  },

  mounted(){
    this.getCommentHandle()
  },

  methods:{
    onLoad(){
      this.page++
      this.getCommentHandle()
    },
    getCommentHandle() {
      // 获取评论
      getComment({page: this.page, goods_id: this.$route.params.id}).then(res => {
        if(res) {
          res.data.data.forEach(el => {
            el.created_at = parseTime(el.created_at)
          });
          this.list = this.list.concat(res.data.data)
          if(res.data.data.length < res.data.per_page) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-goods-comments {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow: hidden;
  overflow-y: auto;
  .solt-right {
    display: inline-block;
    width: 30px;
    height: 30px;
    >img {
      width: 30px;
      height: 30px;
    }
  }
  .search-no {
    text-align:center;
    line-height:100px;
    font-size:12px;
    color:#888;
  }
}
</style>